.react-calendar {
  background-color: #171616;
  color: #e2e8f0;
  border-radius: 8px;
  border: none;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.react-calendar__tile {
  background: none;
  color: #e2e8f0;
  border-radius: 5px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.react-calendar__tile--active {
  background: #ffffffc7 !important;
  color: black !important;
}

.react-calendar__tile--now:not(.react-calendar__tile--active) {
  position: relative;
  background: none;
  color: white;
}

.react-calendar__tile--now:not(.react-calendar__tile--active)::after {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  background: #ffffffc7;
  color: #171616;
  border-radius: 50%;
}

.react-calendar__navigation button {
  color: #e2e8f0;
  border: none;
  background: none;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.react-calendar__navigation button:hover,
.react-calendar__navigation button:focus,
.react-calendar__navigation button:active {
  background: gray;
  color: black;
  border-radius: 5px;
}

.react-calendar__month-view__weekdays {
  background-color: #171616;
  color: #e2e8f0;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.react-calendar__month-view__weekdays__weekday abbr {
  border-bottom: none !important;
  text-decoration: none;
}

.react-calendar__tile--hover:hover,
.react-calendar__tile:hover {
  background: #e2e8f027 !important;
  color: white;
  border-radius: 5px;
}

.past-date {
  background: none !important;
  color: #888888 !important;
}

.calendar-scroll::-webkit-scrollbar {
  width: 2px;
  border-radius: 30px;
}

.calendar-scroll::-webkit-scrollbar-track {
  background: #525151;
}

.calendar-scroll::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 30px;
}

.calendar-scroll::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}
.react-calendar__tile--disabled {
  background: #171616 !important;
  color: #888888 !important;
  pointer-events: none;
}
.available-slot {
  background: #ffffff !important;
  color: black !important;
}
.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px 10px;
}
