.dot-wave {
  display: inline-block;
  width: 80px;
  height: 20px;
}

.dot-wave div {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 3px;
  background-color: #fff;
  border-radius: 50%;
  animation: dot-wave 1.2s linear infinite;
}

.dot-wave div:nth-child(1) {
  animation-delay: -0.32s;
}

.dot-wave div:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes dot-wave {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
