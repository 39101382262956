@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Tan Moncheri";
  src: url("../public/assets/TAN-MONCHERI.ttf") format("truetype");
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Belleza&family=Bodoni+Moda:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Forum&family=Italiana&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Teachers:ital,wght@0,400..800;1,400..800&family=Tenor+Sans&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

.background-container {
  background-color: #fff6ed;
  background-image: url("../public/assets/frame.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -260px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
}

.page {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.page.show {
  opacity: 1;
}

.about-content,
#contact {
  background-image: url("../public/assets/what-we-do-gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#privacy,
#tnc {
  background-image: url("../public/assets/what-we-do-gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#refund,
#support {
  background-image: url("../public/assets/what-we-do-gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

#shipping {
  background-image: url("../public/assets/what-we-do-gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
}

#banner {
  background-image: url("../public/assets/pink-gradient.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  background-position-x: 500px;
}

.footer-banner {
  background-image: url("../public/assets/banner-gradient.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
}

.quote {
  line-height: 50px !important;
}

.heading {
  line-height: 90px !important;
}

.search:focus,
.body-box:focus,
.title:focus,
.comment:focus {
  border: 1px solid #999999b0;
  outline: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-out;
}

@media (max-width: 786px) {
  .footer-banner {
    background-size: cover;
  }
  .quote {
    line-height: 40px !important;
  }
  .heading {
    line-height: 55px !important;
  }
}
