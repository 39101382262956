.therapist-sidebar::-webkit-scrollbar {
  width: 2px;
  border-radius: 30px;
}

.therapist-sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.therapist-sidebar::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 30px;
}

.therapist-sidebar::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}

input[type="radio"].styled-radio {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 14px;
  height: 14px;
  border: 1px solid #424242;
  background-color: #d6d6d68f;
  border-radius: 50%;
  outline: none;
  margin-right: 10px;
  cursor: pointer;
}

input[type="radio"].styled-radio:checked {
  background-color: #696969;
  border: 1px solid #ffffff;
}

.therapist-list {
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.therapist-list.paused {
  animation-play-state: paused;
}
