.body-box::-webkit-scrollbar {
  width: 4px;
  border-radius: 30px;
}

.body-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.body-box::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 30px;
}

.body-box::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.disabled-button {
  opacity: 0.5;
  pointer-events: none;
}
